import normal from './normal';
import navDark from './nav-dark';
import navLight from './nav-light';
import footerDark from './footer-dark';
import footerLight from './footer-light';
import newFooterLinks from './new-footer-links';
import newNormal from './new-normal';
import newLoginButton from './new-login-button';

export default {
  normal,
  'nav-dark': navDark,
  'nav-light': navLight,
  'footer-light': footerLight,
  'footer-dark': footerDark,
  'new-footer-links': newFooterLinks,
  'new-normal': newNormal,
  'new-login-button': newLoginButton,
};
