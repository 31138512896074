export default {
  color: `white`,
  backgroundColor: `gammaRed`,
  borderRadius: `99px`,
  border: `2px white solid`,
  textDecoration: `none`,
  userSelect: `none`,
  cursor: `pointer`,
  fontSize: `12px`,
  fontWeight: 700,
  transition: `all 250ms ease`,
  p: `7px 15px !important`,
  ':visited': {
    color: 'white'
  },
  ':hover': {
    backgroundColor: `#c93d53`
  }
}
