export default {
  color: `newFooterLinks`,
  textDecoration: `none`,
  userSelect: `none`,
  cursor: `pointer`,
  transition: `all 250ms ease`,
  ':hover': {
    color: 'alphaDark'
  }
}
