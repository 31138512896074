import common from './common'

export default {
  ...common.button,
  color: `#7D7D7D`,
  borderRadius: `10px`,
  margin: `0 0 10px 0 !important`,
  width: `400px`,
  height: `60px`,
  border: `2px`,
  fontWeight: 300,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    borderColor: `newLandingBorder`,
    background: `linear-gradient(to right, #fff 84%, rgba(131, 91, 249, 0.99) 84%, rgba(131, 91, 249, 0.6336) 100%)`
  },
  svg: {
    position: `absolute`,
    right: `17px`,
    bottom: `19px`,
  }
}
