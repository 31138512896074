export default {
  color: `headerLink`,
  textDecoration: `none`,
  userSelect: `none`,
  cursor: `pointer`,
  whiteSpace: `pre`,
  marginRight: `0`,
  marginLeft: `0`,
  fontWeight: 700,
  transition: `all 250ms ease`,
  p: 0,
  ':visited': {
    color: 'headerLink',
  },
  ':hover': {
    color: 'hoveredHeaderLink',
  },
};
