import colors from './color-preset';

//Primary
const alpha = {
  alphaLighter: colors.indigo[100],
  alphaLight: colors.indigo[300],
  alpha: colors.indigo[500],
  alphaDark: colors.indigo[600],
  alphaDarker: colors.indigo[800],
};

// Secondary
const beta = {
  betaLighter: colors.purple[100],
  betaLight: colors.purple[300],
  beta: colors.purple[500],
  betaDark: colors.purple[600],
  betaDarker: colors.purple[800],
};

// Reserved
const gamma = {
  gammaRed: colors.red[500],
  gammaDarkRed: colors.red[600],
};

// Reserved
const psi = {};

// Neutral
const omega = {
  omegaLighter: colors.gray[200],
  omegaLight: colors.gray[300],
  omega: colors.gray[500],
  omegaDark: colors.gray[600],
  omegaDarker: colors.gray[800],
};

export default {
  text: colors.gray[800],
  article: colors.gray[800],
  heading: colors.gray[800],

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,

  successLight: colors.green[100],
  success: colors.green[500],
  errorLight: colors.red[100],
  error: colors.red[500],

  warning: colors.yellow[500],
  white: colors.white,
  background: `#f8f8f8`,
  contentBg: colors.white,
  headerBg: `transparent`,
  headerActiveBg: colors.white,
  footerBg: colors.white,

  mute: colors.gray[300],
  highlight: colors.gray[200],

  headerLink: `#c2c2c2`,
  hoveredHeaderLink: `#bababa`,
  themeColor: `#8568d6`,

  newLandingTheme: `#835BF9`,
  newLandingBorder: `#794BFF`,
  newLandingHeader: `#8A52E4`,
  newFooterLinks: `#FCFCFC`,
};
