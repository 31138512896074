import React, { useState, useEffect } from 'react';
import { Link as GLink } from 'gatsby';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import Sticky from 'react-sticky-el';
import { Container, Box, Flex, css } from 'theme-ui';
import Reveal from '@solid-ui-components/Reveal';
import ContentButtons from '@solid-ui-components/ContentButtons';
import WithDefaultContent from '../../WithDefaultContent';
import getImage from '@solid-ui-components/utils/getImage';
import arrow from '../../../../../content/assets/icons/arrow-down-white.svg';
import arrowGray from '../../../../../content/assets/icons/arrow-down-gray.svg';
import arrowBlack from '../../../../../content/assets/icons/arrow-down-black.svg';
import styled from '@emotion/styled';
import Navigation from '@components/Navigation';
import Drawer from '@solid-ui-components/Drawer';
import { languageOptions } from '../../../../../constants/languages.ts';

const styles = {
  wrapper: {
    position: 'relative',
    zIndex: 10,
    '.nav-container': {
      bg: `headerBg`,
      transition: `all 250ms ease-in`,
      overflow: `visible`,
      width: '100%',
      position: 'fixed',
      maxWidth: '100vw',
      paddingTop: '10px',
    },
    '.nav-sticky .nav-container': {
      bg: `headerActiveBg`,
      boxShadow: `0 0 25px rgba(140,152,164,.25)`,
      opacity: `0.9`,
      height: '85px',
      paddingBottom: '10px',
    },
  },
  menuContainer: {
    flexBasis: [`auto`, null, `1/3`],
    minWidth: `auto`,
    order: [4, null, `unset`],
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`,
  },
  headerHome: {
    color: 'white',
  },
  logoContainer: {
    flexShrink: 0,
    mr: [null, null, 3, 5],
  },
  desktopMenu: {
    display: [`none`, null, `block`],
    minWidth: `auto`,
    flexGrow: 1,
  },
  mobileMenu: {
    display: [`flex`, null, `none`],
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginTop: '5px',
    width: `280px`,
    height: 35,
    '@media(max-width: 549px)': {
      width: `calc(100vw - 160px)`,
    },
  },
  mobileButton: {
    marginLeft: `4px`,
    marginRight: `4px`,
    color: `#fff`,
    backgroundColor: `gammaRed`,
    border: `2px solid #fff`,
    borderRadius: `20px`,
    padding: `5px 13px`,
    textDecoration: `none`,
    ':visited': {
      color: '#fff',
    },
    ':hover': {
      backgroundColor: `#c93d53`,
    },
  },
  arrow: {
    width: `11px`,
    position: `absolute`,
    top: `12px`,
    right: `7px`,
  },
  option: {
    borderBottom: `1px solid #DBDBDB`,
    cursor: `pointer`,
    margin: 0,
    padding: `5px`,
    borderLeft: `1px solid #DBDBDB`,
    borderRight: `1px solid #DBDBDB`,
    ':hover': {
      color: `#C9C9C9`,
    },
  },
  firstOption: {
    cursor: `pointer`,
    margin: 0,
    padding: `4px 5px 0px 5px`,
    ':hover': {
      color: `#C9C9C9`,
    },
  },
};

const MobileButton = ({ buttons }) => {
  const button = buttons[2];
  return (
    <a href={button?.link} css={css(styles.mobileButton)}>
      {button?.text}
    </a>
  );
};

const Dropdown = styled.div`
  width: 85px;
  background-color: transparent;
  color: ${({ newLanding }) => {
    if (newLanding) return 'black';
    return '#DBDBDB';
  }};
  font-family: ${({ newLanding }) => {
    if (newLanding) return 'Lato, sans-serif';
  }};
  height: 40px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  margin-top: 8px;
  margin-right: -5px;
  &:hover .dropdownContent {
      display: block !important
    },
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  top: 36px;
  background-color: white;
  min-width: 85px;
  z-index: 1;
`;

const Select = ({ isSticky, newLanding }) => {
  const [language, setLanguage] = useState('IT (IT)');

  useEffect(() => {
    const { pathname } = window.location;
    languageOptions.map(({ link, title }) => {
      if (pathname.includes(link)) {
        setLanguage(title);
      }
    });
  }, []);

  const openLink = (e, { link }) => {
    const text = e.target.textContent;
    setLanguage(text);
    if (newLanding && !link.includes('new-landing'))
      window.location.href = link + 'new-landing/';
    else window.location.href = link;
  };

  return (
    <Dropdown newLanding={newLanding}>
      <p css={css(styles.firstOption)}>{language}</p>
      {isSticky ? (
        <img
          src={newLanding ? arrowBlack : arrow}
          alt='arrow'
          style={styles.arrow}
        />
      ) : (
        <img
          src={newLanding ? arrowBlack : arrowGray}
          alt='arrow'
          style={styles.arrow}
        />
      )}
      <DropdownContent isSticky={isSticky} className='dropdownContent'>
        {languageOptions
          .filter((option) => option.title !== language)
          .map((option, index) => {
            return (
              <p
                className='dropOption'
                css={css(styles.option)}
                key={index}
                onClick={(e) => openLink(e, option)}
              >
                {option.title}
              </p>
            );
          })}
      </DropdownContent>
    </Dropdown>
  );
};

const HeaderBlock01 = ({
  content: { images, collection, buttons, text_1, text_2 },
  menuJustify,
  newLanding,
  transparent = false,
}) => {
  const [isSticky, setSticky] = useState(true);
  const [mobileMenu, mobileMenuLanguages] = collection;
  return (
    <>
      <Sticky
        enabled='true'
        stickyClassName='nav-sticky'
        css={css(styles.wrapper)}
        onFixedToggle={() => setSticky(!isSticky)}
      >
        <Container
          className='nav-container'
          sx={
            isSticky &&
            transparent && { backgroundColor: 'transparent !important' }
          }
          px={3}
        >
          <Flex
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={styles.logoContainer}>
              <GLink to='/' alt={text_1?.text} title={text_2?.text}>
                {newLanding ? (
                  <Img
                    image={getImage({
                      ...images?.[1]?.src,
                      __typename: 'ImageSharp_medium',
                    })}
                    alt={images?.[1]?.alt}
                    css={css(styles.logo)}
                    key={images?.[1]?.alt}
                    objectFit='contain'
                    loading='lazy'
                  />
                ) : (
                  <>
                    <Img
                      image={getImage({
                        ...images?.[1]?.src,
                        __typename: 'ImageSharp_medium',
                      })}
                      alt={images?.[1]?.alt}
                      css={css(styles.logo)}
                      objectFit='contain'
                      loading='eager'
                      style={
                        !isSticky || !transparent
                          ? {
                              display: 'none',
                            }
                          : undefined
                      }
                      key={images?.[1]?.alt}
                    />
                    <Img
                      image={getImage({
                        ...images?.[0]?.src,
                        __typename: 'ImageSharp_medium',
                      })}
                      alt={images?.[0]?.alt}
                      css={css(styles.logo)}
                      loading='lazy'
                      objectFit='contain'
                      style={
                        isSticky && transparent
                          ? {
                              display: 'none',
                            }
                          : undefined
                      }
                      key={images?.[0]?.alt}
                    />
                  </>
                )}
              </GLink>
            </Box>
            <Box sx={styles.desktopMenu}>
              <Reveal effect='fadeInDown'>
                <Flex
                  sx={{
                    alignItems: `center`,
                    justifyContent: menuJustify,
                  }}
                  m='-3'
                >
                  <Select isSticky={isSticky} newLanding={newLanding} />
                  <Box m='3'>
                    <ContentButtons
                      content={[
                        ...buttons.map((button) => ({
                          ...button,
                          noprefetch: true,
                        })),
                      ]}
                      space={0}
                    />
                  </Box>
                </Flex>
              </Reveal>
            </Box>
            <Box sx={styles.mobileMenu}>
              <MobileButton buttons={buttons} />
              <Drawer>
                <Navigation
                  variant='vertical'
                  headingProps={{ variant: 'h3' }}
                  wrapperStyle={{ flexDirection: 'column' }}
                  items={[
                    {
                      title: mobileMenu.title.text,
                      items: mobileMenu.buttons?.map((item) => ({
                        name: item.text,
                        slug: item.link,
                      })),
                    },
                    {
                      title: mobileMenuLanguages.title.text,
                      items: languageOptions?.map((item) => ({
                        name: item.title,
                        url: item.link,
                      })),
                    },
                  ]}
                />
              </Drawer>
            </Box>
          </Flex>
        </Container>
      </Sticky>
    </>
  );
};

HeaderBlock01.defaultProps = {
  menuJustify: `flex-end`,
};

export default WithDefaultContent(HeaderBlock01);
