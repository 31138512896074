import common from './common'

export default {
  ...common.button,
  color: `white`,
  borderRadius: `10px`,
  margin: `0 0 10px 0 !important`,
  width: `400px`,
  fontWeight: 600,
  height: `60px`,
  border: `2px`,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `newLandingBorder`,
    borderColor: `#A082FA`
  },
}
