/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

import { merge } from 'theme-ui';
import baseTheme from '@solid-ui-theme';

const blue = {
  100: '#CCF9FF',
  200: '#9AEDFF',
  300: '#68DBFF',
  400: '#42C7FF',
  500: '#04A5FF',
  600: '#0280DB',
  700: '#025FB7',
  800: '#014393',
  900: '#00307A',
};

const violet = {
  300: '#EAE8FE',
  500: '#835BF9',
  600: '#6A3EEA',
  800: '#17278D',
};

const red = {
  100: '#FFE7D6',
  200: '#FFCAAD',
  300: '#FFA683',
  400: '#FF8365',
  500: '#FF4A32',
  600: '#DB2B24',
  700: '#B7191F',
  800: '#930F20',
  900: '#7A0920',
};

export default merge(baseTheme, {
  colors: {
    betaLighter: red[100],
    betaLight: red[300],
    beta: red[600],
    betaDark: red[700],
    betaDarker: red[800],
    alphaLighter: violet[300],
    alphaLight: violet[300],
    alpha: violet[500],
    alphaDark: violet[600],
    alphaDarker: violet[800],
    background: `#FFFFFF`,
    headerBg: `transparent`,
    footerBg: `transparent`,
  },
});
