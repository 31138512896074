export default {
  color: `black`,
  textDecoration: `none`,
  userSelect: `none`,
  cursor: `pointer`,
  whiteSpace: `pre`,
  transition: `all 250ms ease`,
  fontFamily: `Lato, sans-serif`,
  fontWeight: 700,
  marginLeft: `30px !important`,
  p: 0,
  ':hover': {
    textDecoration: `underline !important`
  }
}
