export default {
  color: `#2d3748`,
  textDecoration: `none`,
  userSelect: `none`,
  cursor: `pointer`,
  whiteSpace: `pre`,
  fontWeight: 700,
  marginLeft: `30px`,
  transition: `all 250ms ease`,
  p: 0,
  ':hover': {
    color: 'black',
  },
};
