export default {
  bg: `#A082FA`,
  borderRadius: `lg`,
  transition: `
    transform 250ms ease,
    box-shadow 250ms ease,
    color 250ms ease
  `,
  boxShadow: `0 0 35px rgba(140,152,164,.125)`,
  height: `auto`,
  color: `white`,
  fontFamily: 'Poppins, Ubuntu',
  fontWeight: 400,
  fontSize: `30px`,
  lineHeight: `48px`,
  position: `relative`,
  overflow: `hidden`,
};
