const heading = {
  display: `block`,
  color: `heading`,
  textDecoration: `none`,
  mb: 3,
  fontFamily: `body`
}

export default {
  heading: {
    ...heading
  },
  h1: {
    ...heading,
    fontSize: `48px`,
    fontWeight: `600`
  },
  headerThin: {
    ...heading,
    fontSize: `48px`,
    fontWeight: `300`
  },
  h2: {
    ...heading,
    fontSize: 6
  },
  h3: {
    ...heading,
    fontSize: 5
  },
  h4: {
    ...heading,
    fontSize: 4
  },
  h5: {
    ...heading,
    fontSize: 3
  },
  h6: {
    ...heading,
    fontSize: 2
  },
  medium: {
    fontSize: 3,
    mb: 3
  },
  small: {
    fontSize: 1,
    m: 0
  },
  xsmall: {
    fontSize: 0,
    m: 0
  },
  p: {
    fontFamily: `body`,
    mb: 3
  },
  text_1: {
    ...heading,
    fontSize: `18px`,
    fontWeight: `300`
  }
}
